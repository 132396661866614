import React, { Component } from 'react';
import flatpickr from "flatpickr";
import '../styles/_contact.scss';


class Contact extends Component {
  render() {
    return (
      <>
        <div className="contactForm">
          <form>
            <h1>Lets Work. Together.</h1>
            <h3>Help us by filling in the blanks.</h3>
            <input placeholder="Name" type="text" required="" name="name" />
            <input
              placeholder="Company"
              type="text"
              required=""
              name="company"
            />
            <div
              className="dropdown"
              data-control="checkbox-dropdown"
              id="solution"
            >
              <label className="dropdown-label">Solution</label>
              <div className="dropdown-list">
                <label className="dropdown-option">
                  Strategy
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="strategy"
                  />
                </label>
                <br />
                <label className="dropdown-option">
                  Analytics
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="analytics"
                  />
                </label>
                <br />
                <label className="dropdown-option">
                  Media Planning
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="Selection 3"
                  />
                </label>
                <br />
                <label className="dropdown-option">
                  R &amp; D
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="Selection 4"
                  />
                </label>
                <br />
                <label className="dropdown-option">
                  Creative
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="Selection 5"
                  />
                </label>
                <br />
                <label className="dropdown-option">
                  Video Production
                  <input
                    type="checkbox"
                    name="dropdown-group"
                    value="Selection 5"
                  />
                </label>
              </div>
              </div>
              <input placeholder="Email" type="text" required="" name="email" />
              <input
                placeholder="Phone Number"
                type="tel"
                required=""
                name="tel"
                id="phone-input"
                pattern="[\+]\d{2}[\(]\d{2}[\)]\d{4}[\-]\d{4}"
              />
              <input
                placeholder="Industry"
                type="text"
                required=""
                name="industry"
              />
              <br />
              <input type="text" id="amount" name="lowAmount" readOnly />
              <input type="text" id="amount2" name="highAmount" readOnly />
              <div className="wrapper">
                <div className="budget form-slide">
                  <p id="budget">Budget</p>
                  <div className="slider-wrapper">
                    <div id="slider-range" />
                    <div className="range-wrapper">
                      <div className="range" />
                      <div className="range-alert">+</div>
                    </div>
                    <div className="marker marker-0">$10,000</div>
                    <div className="marker marker-25">$70,000</div>
                    <div className="marker marker-50">$130,000</div>
                    <div className="marker marker-75">$190,000</div>
                    <div className="marker marker-100">$250,000+</div>
                  </div>
                </div>
                <div className="calendar form-slide">
                  <div id="calendarOptions">
                    <select name="frequency" id="frequency" defaultValue="">
                      <option value="" disabled>
                        Frequency
                      </option>
                      <option value="one">One Time</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="annually">Annually</option>
                    </select>
                  </div>
                </div>
                <input
                  className="flatpickr"
                  type="text"
                  placeholder="Start"
                  id="start"
                  data-input
                />
                <a className="input-button" data-clear>
                  <i className="icon-close" />
                </a>
                <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735738/media-news/rightArrow.svg" />
                <input
                  className="flatpickr"
                  type="text"
                  placeholder="Finish"
                  id="end"
                  data-input
                />
                <input
                  type="text"
                  name="location"
                  placeholder="Location"
                  id="location"
                />
              </div>
              <div className="textarea form-slide">
                <textarea
                  id="detailsText"
                  name="Details"
                  placeholder="Did we miss anything?"
                />
                <input id="upfile" type="file" name="files" multiple />
                <div className="file-dummy">
                  <div className="default">Drag &amp; Drop A File</div>
                </div>
              </div>
            
            <ul className="nav-dots">
              <li className="active" />
              <li />
              <li />
            </ul>
            <a href="" className="arrow-prev">
              <svg width="30px" height="30px" viewBox="0 0 50 80">
                <polyline
                  fill="none"
                  stroke="#1277f6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="
              45.63,75.8 0.375,38.087 45.63,0.375 "
                />
              </svg>
            </a>
            <a href="" className="arrow-next">
              <svg width="30px" height="30px" viewBox="0 0 50 80">
                <polyline
                  fill="none"
                  stroke="#1277f6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="
              0.375,0.375 45.63,38.087 0.375,75.8 "
                />
              </svg>
            </a>
            <button>Submit</button>
          </form>
        </div>
        <div className="terms">
          <p>
            <a href="#">Terms of Use</a> | <a href="#">Privacy Policy</a> |{' '}
            <a href="#">Copyright</a>
          </p>
        </div>
        <div className="bg">
          <div id="satellite">
            <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735738/media-news/mnsatellite.svg" />
          </div>
          <div id="cloud-wrap">
            <svg
              className="svg-sun"
              version="1.1"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMinYMin meet"
            >
              <circle cx="50" cy="50" r="35" id="sun" />
            </svg>
            <div className="x1">
              <div className="cloud1" />
            </div>
            <div className="x4">
              <div className="cloud2" />
            </div>
            <div className="x5">
              <div className="cloud1" />
            </div>
          </div>
          <div id="sailBoat">
            <img src="https://res.cloudinary.com/mattkellough/image/upload/v1556735738/media-news/sailBoat.svg" />
          </div>
        </div>
      </>
    );
  }
}

export default Contact;
