import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import '../styles/_solutions.scss';

class Solutions extends Component {

  boxIn = (e, stroke, fill, out = false) => {
    let target = e.target;
    let color = '#1277f6';
    if (out) {
      color = '#ffffff';
    }

    if (e.target.tagName !== 'DIV') {
      target = e.target.parentElement;
    }
    if (stroke) {
      Array.from(
        target.querySelectorAll('svg .cls-1'),
        element => (element.style.stroke = color)
      );
    }
    if (fill) {
      Array.from(
        target.querySelectorAll('svg .cls-1'),
        element => (element.style.fill = color)
      );
    }
    target.querySelector('p').style.color = color;
  };
  render() {
    return (
      <>
        <div id="contactheading">
          <h1>How Can We Help?</h1>
          <h3>Choose a category to get started.</h3>
        </div>
        <div id="bothboxes">
          <div className="boxes">
            <div
              className="small-boxes"
              id="strategy"
              onMouseEnter={e => this.boxIn(e, true, true)}
              onMouseLeave={e => this.boxIn(e, true, true, true)}
            >
              <SVG src="https://res.cloudinary.com/mattkellough/image/upload/v1556815137/media-news/strategy.svg" />
              <p>Strategy</p>
            </div>
            <div
              className="small-boxes"
              id="analytics"
              onMouseEnter={e => this.boxIn(e, true, false)}
              onMouseLeave={e => this.boxIn(e, true, false, true)}
            >
              <SVG src="https://res.cloudinary.com/mattkellough/image/upload/v1556815137/media-news/analytics.svg" />
              <p>Analytics</p>
            </div>
            <div
              className="small-boxes"
              id="mediaPlanning"
              onMouseEnter={e => this.boxIn(e, true, false)}
              onMouseLeave={e => this.boxIn(e, true, false, true)}
            >
              <SVG src="https://res.cloudinary.com/mattkellough/image/upload/v1556815137/media-news/media-planning.svg" />
              <p>Media Planning</p>
            </div>
            <div
              className="small-boxes"
              id="r-d"
              onMouseEnter={e => this.boxIn(e, true, false)}
              onMouseLeave={e => this.boxIn(e, true, false, true)}
            >
              <SVG src="https://res.cloudinary.com/mattkellough/image/upload/v1556815137/media-news/r-d.svg" />
              <p>R &amp; D</p>
            </div>
            <div
              className="small-boxes"
              id="creative"
              onMouseEnter={e => this.boxIn(e, true, true)}
              onMouseLeave={e => this.boxIn(e, true, true, true)}
            >
              <SVG src="https://res.cloudinary.com/mattkellough/image/upload/v1556824712/media-news/creative.svg" />
              <p>Creative</p>
            </div>
            <div
              className="small-boxes"
              id="videoProduction"
              onMouseEnter={e => this.boxIn(e, true, true)}
              onMouseLeave={e => this.boxIn(e, true, true, true)}
            >
              <SVG src="https://res.cloudinary.com/mattkellough/image/upload/v1556815137/media-news/video-production.svg" />
              <p>Video Production</p>
            </div>
          </div>
        </div>
        <div className="brainContainer">
          <SVG
            className="brain"
            src="https://res.cloudinary.com/mattkellough/image/upload/v1556815137/media-news/brain.svg"
          />
          <span>Frontal Lobe</span>
          <span>Temporal Lobe</span>
          <span>Brain Stem</span>
          <span>Cerebellum</span>
          <span>Occipital Lobe</span>
          <span>Parietal Lobe</span>
          <SVG
            className="brainMediaPlanning"
            src="https://res.cloudinary.com/mattkellough/image/upload/v1556815137/media-news/brain-stem.svg"
          />
        </div>
      </>
    );
  }
}

export default Solutions;
