import React, { Component } from 'react';
import { TweenMax } from 'gsap/all';
import '../styles/_visuals.scss';

class Visuals extends Component {
  componentDidMount() {
    this.showThumbs();
  }

  showThumbs() {
    const pictures = document.getElementsByClassName('pictures');

    document.querySelector('body').style.overflowX = 'hidden';
    TweenMax.staggerFrom(
      pictures,
      1,
      { x: 1100, autoAlpha: 0, ease: 'Power0.easeNone', delay: 0.5 },
      0.3
    );
    TweenMax.delayedCall(4, this.overflow);
  }

  showVideo(e) {
    let target = e.target;
    if (target.tagName !== 'DIV') {
      if (target.tagName === 'P') {
        target = e.target.parentElement.parentElement;
      } else {
        target = e.target.parentElement;
      }
    }
    if (!target.classList.contains('current')) {
      Array.from(document.getElementsByClassName('pictures'), element =>
        element.classList.remove('current')
      );

      target.classList.add('current');

      const { text, video } = target.dataset;
      const textDiv = document.getElementById(text);
      const videoFrame = document.getElementById('iframe-video');
      const videoContainer = document.querySelector('.video-container');

      if (videoContainer.querySelector('iframe') !== null) {
        videoContainer.querySelector('iframe').remove();
      }

      Array.from(document.getElementsByClassName('videoText'), element =>
        element.classList.remove('activeText')
      );

      const iframeVideo = document.createElement('iframe');
      iframeVideo.src = video;

      videoContainer.classList.add('show');

      videoFrame.appendChild(iframeVideo);

      textDiv.classList.add('activeText');
    }
  }

  overflow() {
    document.querySelector('body').style.overflowX = '';
  }

  render() {
    return (
      <>
        <div id="av-top">
          <a href="/work/">V | A</a>
        </div>
        <div id="container">
          <div id="pics">
            <div className="picturesblank" />
            <div
              id="party1"
              className="pictures mask"
              data-text="partytext"
              data-video="http://player.vimeo.com/video/57768595?title=0&amp;byline=0&amp;portrait=0&amp;badge=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>EZ VIP</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/party.jpg"
                id="party"
              />
            </div>
            <div className="picturesblank" />
            <div
              id="pitbull1"
              className="pictures mask"
              data-text="pitbulltext"
              data-video="https://www.youtube.com/embed/EPo5wWmKEaI?autoplay=0&rel=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>Pitbull</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/pitbull.jpg"
                id="pitbull"
              />
            </div>
            <div className="picturesblank" />
            <div className="picturesblank" />
            <div
              id="makeup1"
              className="pictures mask"
              data-text="barrymtext"
              data-video="http://player.vimeo.com/video/38788095?title=0&amp;byline=0&amp;portrait=0&amp;badge=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>Barry M</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/makeup.jpg"
                id="makeup"
              />
            </div>
            <div
              id="leatherjacket1"
              className="pictures mask"
              data-text="leatherjackettext"
              data-video="http://player.vimeo.com/video/55351457?title=0&amp;byline=0&amp;portrait=0&amp;badge=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>Sebastian Mikael</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/leatherjacket.jpg"
                id="leatherjacket"
              />
            </div>
            <div className="picturesblank" />
            <div className="picturesblank" />
            <div className="picturesblank" />
            <div
              id="redbg1"
              className="pictures mask"
              data-text="redbgtext"
              data-video="https://www.youtube.com/embed/O2MNdFKUDVg?autoplay=0&rel=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>Katy Tiz</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/redbg.jpg"
                id="redbg"
              />
            </div>
            <div className="picturesblank" />
            <div className="picturesblank" />
            <div className="picturesblank" />
            <div className="picturesblank" />
            <div className="picturesblank" />
            <div
              id="football1"
              className="pictures mask"
              data-text="kfctext"
              data-video="https://www.youtube.com/embed/JMGxm_eB31U?autoplay=0&rel=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>KFC</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/football.jpg"
                id="football"
              />
            </div>
            <div id="createvis">
              <p>
                We create. Visuals <br />
                like no other.
              </p>
              <div id="learnmorecreate">
                <a href="/hello">Learn More</a>
                <a href="/hello">
                  <img
                    alt="thumbnail"
                    src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/arrow.png"
                  />
                </a>
              </div>
            </div>
            <div
              id="tulisa1"
              className="pictures mask"
              data-text="tulisatext"
              data-video="https://www.youtube.com/embed/eP_eI5T_deU?autoplay=0&rel=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>Tulisa</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/tulisa.jpg"
                id="tulisa"
              />
            </div>
            <div
              id="allstar1"
              className="pictures mask"
              data-text="smirnoffText"
              data-video="https://player.vimeo.com/video/261227987?title=0&amp;amp;byline=0&amp;amp;portrait=0&amp;amp;badge=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>Smirnoff</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/smirnoff1.jpeg"
                id="allstar"
              />
            </div>
            <div
              id="golddress1"
              className="pictures mask"
              data-text="golddresstext"
              data-video="https://www.youtube.com/embed/LbXSQDl_ivE?autoplay=0&rel=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>Trina</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/golddress.jpg"
                id="golddress"
              />
            </div>
            <div className="picturesblank" />
            <div
              id="brasil1"
              className="pictures mask"
              data-text="worldcuptext"
              data-video="https://www.youtube.com/embed/TGtWWb9emYI?autoplay=0&rel=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>FIFA</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/brasil.jpg"
                id="brasil"
              />
            </div>
            <div
              id="pitbullMarcAnthony"
              className="pictures mask"
              data-text="pitbullmarcanthonytext"
              data-video="https://www.youtube.com/embed/ISxrbRx4_pU?autoplay=0&rel=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>Marc Anthony &amp; Pitbull</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/pitbullMarcAnthony.jpg"
                id="pitbullMarc"
              />
            </div>
            <div
              id="rockineve1"
              className="pictures mask"
              data-text="nyetext"
              data-video="http://player.vimeo.com/video/59375573?title=0&amp;byline=0&amp;portrait=0&amp;badge=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>NYE 2012</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/rockineve.jpg"
                id="rockineve"
              />
            </div>
            <div className="picturesblank" />
            <div className="picturesblank" />
            <div className="picturesblank" />
            <div
              id="asap"
              className="pictures mask"
              data-text="asaptext"
              data-video="http://player.vimeo.com/video/53281520?title=0&amp;byline=0&amp;portrait=0&amp;badge=0"
              onClick={e => this.showVideo(e)}
            >
              <h3>
                <p>A$AP Rocky</p>
              </h3>
              <img
                alt="thumbnail"
                src="https://res.cloudinary.com/mattkellough/image/upload/v1556735737/media-news/asap.jpg"
              />
            </div>
          </div>

          <div className="video-container">
            <div id="iframe-video" />
          </div>

          <div className="videoText" id="tulisatext">
            <span className="keys">
              Client: <span className="values">Defjam Europe</span>{' '}
            </span>
            <span className="keys">
              Artist: <span className="values">Tulisa</span>
            </span>
            <span className="keys">
              Title: <span className="values">Young</span>{' '}
            </span>
            <span className="keys">
              Directed By: <span className="values">Chris Watts</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Tamara Brooks</span>
            </span>
            <span className="keys">
              Writer: <span className="values">Cliff Cohen</span>
            </span>
            <span className="keys">
              DP: <span className="values">M.L. Fleming</span>
            </span>
          </div>
          <div className="videoText" id="partytext">
            <span className="keys">
              Client: <span className="values">EZVIP</span>{' '}
            </span>
            <span className="keys">
              Title: <span className="values">Access</span>{' '}
            </span>
            <span className="keys">
              Directed By: <span className="values">Media + News</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Derrick Turton </span>
            </span>
            <span className="keys">
              Writer: <span className="values">Al Nelson</span>
            </span>
            <span className="keys">
              DP: <span className="values">Media + News</span>
            </span>
          </div>
          <div className="videoText" id="barrymtext">
            <span className="keys">
              Client: <span className="values">Barry M</span>{' '}
            </span>
            <span className="keys">
              Artist: <span className="values">Tulisa</span>
            </span>
            <span className="keys">
              Title: <span className="values">Pop</span>{' '}
            </span>
            <span className="keys">
              Directed By: <span className="values">Chris Watts</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Tamara Brooks</span>
            </span>
            <span className="keys">
              Writer: <span className="values">Cliff Cohen</span>
            </span>
            <span className="keys">
              DP: <span className="values">M.L. Fleming</span>
            </span>
          </div>
          <div className="videoText" id="nyetext">
            <span className="keys">
              Client: <span className="values">ABC</span>{' '}
            </span>
            <span className="keys">
              Title: <span className="values">Dick Clark's New Years Eve</span>{' '}
            </span>
            <span className="keys">
              Venue: <span className="values">New York City</span>
            </span>
            <span className="keys">
              Directed By: <span className="values">Media + News</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Kim Sanchez</span>
            </span>
            <span className="keys">
              DP: <span className="values">Media + News</span>
            </span>
          </div>
          <div className="videoText" id="golddresstext">
            <span className="keys">
              Client: <span className="values">Slip N’ Slide Records</span>{' '}
            </span>
            <span className="keys">
              Artist: <span className="values">Trina</span>
            </span>
            <span className="keys">
              Title: <span className="values">Million Dollar Girl </span>{' '}
            </span>
            <span className="keys">
              Directed By: <span className="values">Gil Green</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Media + News</span>
            </span>
            <span className="keys">
              BTS: <span className="values">Media + News</span>
            </span>
          </div>
          <div className="videoText" id="leatherjackettext">
            <span className="keys">
              Client:{' '}
              <span className="values">Slip N&rsquo; Slide Records</span>
            </span>
            <span className="keys">
              Artist: <span className="values">Sebastian Mikael</span>
            </span>
            <span className="keys">
              Title: <span className="values">Chasing Pavements</span>
            </span>
            <span className="keys">
              Directed By: <span className="values">Fernando Ferro</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Randy Acker</span>
            </span>
            <span className="keys">
              Photography: <span className="values">M.L. Fleming</span>
            </span>
          </div>
          <div className="videoText" id="pitbulltext">
            <span className="keys">
              Client: <span className="values">RCA Records</span>
            </span>
            <span className="keys">
              Artist: <span className="values">Pitbull</span>
            </span>
            <span className="keys">
              Title: <span className="values">Give Me Everything</span>
            </span>
            <span className="keys">
              Directed By: <span className="values">David Russo</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Derrick Turton</span>
            </span>
            <span className="keys">
              BTS: <span className="values">Media + News</span>
            </span>
          </div>
          <div className="videoText" id="pitbullmarcanthonytext">
            <span className="keys">
              Client: <span className="values">RCA Records</span>
            </span>
            <span className="keys">
              Artist: <span className="values">Pitbull ft. Marc Anthony</span>
            </span>
            <span className="keys">
              Title: <span className="values">Rain Over Me</span>
            </span>
            <span className="keys">
              Directed By: <span className="values">David Russo</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Derrick Turton</span>
            </span>
            <span className="keys">
              BTS: <span className="values">Media + News</span>
            </span>
          </div>
          <div className="videoText" id="redbgtext">
            <span className="keys">
              Client: <span className="values">Atlantic Records</span>
            </span>
            <span className="keys">
              Artist: <span className="values">Katy Tiz</span>
            </span>
            <span className="keys">
              Title: <span className="values">Whistle</span>
            </span>
            <span className="keys">
              Directed By: <span className="values">James Larese</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Media + News</span>
            </span>
          </div>
          <div className="videoText" id="worldcuptext">
            <span className="keys">
              Client: <span className="values">FIFA / Sony</span>
            </span>
            <span className="keys">
              Artist: <span className="values">Pitbull</span>
            </span>
            <span className="keys">
              Title: <span className="values">We Are One</span>
            </span>
            <span className="keys">
              Directed By: <span className="values">Ben Mor</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Coleen Haynes</span>
            </span>
            <span className="keys">
              Writer: <span className="values">Armando Perez</span>
            </span>
            <span className="keys">
              Technicals: <span className="values">Media + News</span>
            </span>
          </div>
          <div className="videoText" id="kfctext">
            <span className="keys">
              Client: <span className="values">KFC</span>
            </span>
            <span className="keys">
              Title: <span className="values">Game Day Bucket</span>
            </span>
            <span className="keys">
              Directed By: <span className="values">Guillermo Peluffo</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Craig Witaker</span>
            </span>
            <span className="keys">
              Technicals: <span className="values">Media + News</span>
            </span>
          </div>
          <div className="videoText" id="asaptext">
            <span className="keys">
              Client: <span className="values">Polo Grounds</span>{' '}
            </span>
            <span className="keys">
              Artist: <span className="values">A$AP Rocky</span>
            </span>
            <span className="keys">
              Title: <span className="values">Long Live A$AP</span>{' '}
            </span>
            <span className="keys">
              Directed By: <span className="values">Media + News</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Derrick Turton</span>
            </span>
          </div>
          <div className="videoText" id="smirnoffText">
            <span className="keys">
              Client: <span className="values">Smirnoff</span>{' '}
            </span>
            <span className="keys">
              Title: <span className="values">Tap's Revival in Harlem</span>
            </span>
            <span className="keys">
              Artist: <span className="values">Cartier Williams</span>{' '}
            </span>
            <span className="keys">
              Director: <span className="values">Stacey Lee</span>
            </span>
            <span className="keys">
              Producer: <span className="values">Derek Cohen</span>
            </span>
            <span className="keys">
              CD: <span className="values">Media + News</span>
            </span>
          </div>
          <div className="legend">
            <p>
              DP: Director of Photography | BTS: Behind the Scenes | CD:
              Creative Direction{' '}
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Visuals;
